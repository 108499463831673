import React from "react"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Title from "../components/Title"
import { v4 } from "uuid"

const GalleryPage = ({data}) => {
  const {markdownRemark: markdown} = data
  
  return <Layout>
    <Seo title={markdown.frontmatter.title} />
    
    {
        markdown.frontmatter.title ?
        <Title className="text-center">
        {markdown.frontmatter.title}
        </Title>
        :
        <></>
    }
    <div className="lg:grid lg:grid-cols-3 lg:gap-3">
    {
        markdown.frontmatter.images.map((item, index) => {
                const image = getImage(item.image)

                return <motion.div 
                          key={v4()} 
                          initial={{translateY: "50px"}} 
                          whileInView={{translateY: 0}}
                          transition={{duration: 0.5}}
                          >
                  <GatsbyImage key={index} image={image} alt={`Billede ` + (index+1)} className="my-4 lg:my-0 lg:w-full lg:h-128"/>
                </motion.div>
            }
        )
    }
    </div>
    
  </Layout>
}

export const query = graphql`
query {
  markdownRemark(fields: { slug: { eq: "/gallery/" } }) {
    frontmatter {
      title
      images {
        image {
            childImageSharp {
                gatsbyImageData(
                    width: 1000
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
      }
    }
  }
}
`

export default GalleryPage
